import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Platform } from "react-native";

function App() {
  const os = Platform.OS; // This will return "web"
  var device = ""
  if (os === 'web') {
    // Use the browser's user agent to detect the OS
    const userAgent = navigator.userAgent;
  
    if (/android/i.test(userAgent)) {
      device = "android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      device = "apple";
    } else if (/Macintosh/.test(userAgent)) {
      device = "apple";
    } else if (/Windows/.test(userAgent)) {
      device = "android";
    } else if (/Linux/.test(userAgent)) {
      device = "android";
    } else {
      device = "android";
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="alzheimerscom" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/app/alz-fundraising-test/id6448862158" : "https://play.google.com/store/apps/details?id=com.nuclavis.alzheimers") } />
          <Route path="rospark" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/my-icecycle-test/id6473903560" : "https://play.google.com/store/apps/details?id=com.nuclavis.rospark") } />
          <Route path="roswellride" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/my-rfr-test/id6474823863" : "https://play.google.com/store/apps/details?id=com.nuclavis.rideforroswell") } />
          <Route path="ahayouthmarket" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/aha-schools-test/id6451151625" : "https://play.google.com/store/apps/details?id=com.aha_jump.android.communicator") } />
          <Route path="ahaheartwalk" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/app/aha-heart-walk-test-new/id6479564406" : "https://play.google.com/store/apps/details?id=com.aha.hw.communicator") } />
          <Route path="ahacyclenation" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/aha-cyclenation-test/id6478751877" : "https://play.google.com/store/apps/details?id=com.aha.cyclenation") } />
          <Route path="nationalkidney" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/national-kidney-test/id6670618497" : "https://play.google.com/store/apps/details?id=com.nuclavis.nationalkidney") } />
          <Route path="makeawish" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/make-a-wish-fundraising-test/id6648756780" : "https://play.google.com/store/apps/details?id=com.nuclavis.makeawish") } />
          <Route path="autismcom" Component={() => window.location.href = (device == "apple" ? "https://apps.apple.com/us/app/autism-speaks-fundraising-test/id6448863749" : "https://play.google.com/store/apps/details?id=com.charitydynamics.aswalk") } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
